import { createStore } from 'vuex';
import VuexORM from '@vuex-orm/core'
import League from '@/models/league/League'
import Season from '@/models/season/Season'

// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(League)
database.register(Season)

// Create Vuex Store and register database through Vuex ORM.
export default createStore({
  plugins: [VuexORM.install(database)]
})