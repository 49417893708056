<template>
  <!DOCTYPE html>
  <html lang="en">

  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Forgot Password</title>
  </head>

  <body class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <img src="../../src/assets/img/HR-Logo-Texture.svg" style="height:100px" />
        </div>
        <div class="card-body">
          <p class="login-box-msg">Enter your email address</p>

          <form @submit.prevent="onSubmitHandler" method="post">
            <div class="input-group mb-3">
              <input type="email" name="email" v-model="form.email" class="form-control" placeholder="Enter email">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <small class="text-danger">{{ errors?.email?.[0] }}</small>
            <!--<small class="text-danger">{{errors?.message}}</small>-->
            <div class="row justify-content-center mt-3">
              <div class="col-5">
                <!-- <button type="submit" class="btn btn-primary btn-block" :class="disable? 'disabled' : ''" >Sign In</button> -->
                <button type="submit" class="btn btn-primary btn-block" :disabled="isSubmit">{{ isSubmit ? "Submitting..."
                  :
                  "Submit" }}</button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <router-link to="/" class="nav-link login-box-msg mt-4 pb-0">Login ?</router-link>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.login-box -->

  </body>

  </html>
</template>
    
<script>
// import {ErrorMessage } from "vee-validate";
import { ForgotPassword } from "@/services/userService"
import Swal from "sweetalert2"
export default {
  name: 'Password-Forgot',
  data() {
    return {
      isSubmit: false,
      form: {
        email: '',
      },
      errors: {},
    }
  },
  mounted() {
    this.makeBodyCollaspable();
  },
  methods: {
    makeBodyCollaspable() {
      let width = window.screen.width;
      if (width < 991) {
        let body = document.getElementById('app-body')
        body.classList.add('sidebar-collapse')
        body.classList.remove('sidebar-open')
        body.classList.add('sidebar-closed')
      }
    },
    async onSubmitHandler() {
      this.isSubmit = true;
      await ForgotPassword(this.form)

        .then(async res => {
          let response = res.data;

          if (response.status == 1) {
            Swal.fire({
              icon: 'success',
              title: response.message,
              showConfirmButton: false,
              timer: 3500
            })
            this.isSubmit = false;
          } else {
            this.isSubmit = false;
            Swal.fire({
              icon: 'error',
              title: response.message,
              showConfirmButton: false,
              timer: 3000
            })
          }
        }).catch(err => {
          this.isSubmit = false;
          this.errors = err?.response?.data;
          if (this.errors) {
            let title = 'Opps Something went wrong.'
            if (this.errors.error != null) {
              title = this.errors.error
            }
            Swal.fire({
              icon: 'error',
              title: title,
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
    },
  },
}
</script>