// import axios from '@/utils/auth';
import commonAxios from '@/utils/common';
import axios from '@/utils/auth';

// login user
export const login = async (data) => await commonAxios.post(`login`, data);

export const getUserToken = () => {
    return localStorage.getItem("api_token")
}

export const setUserData = (data) => {
    localStorage.setItem("api_token", data.remember_token)
    localStorage.setItem("active_role", data.role_id)
    localStorage.removeItem("active_tenant");
    localStorage.removeItem("active_profile");
}

export const removeUserData = () => {
    localStorage.removeItem("api_token")
    localStorage.removeItem("active_role")
    localStorage.removeItem("active_tenant")
    localStorage.removeItem("active_profile")
    localStorage.clear()
}
export const setUserProfile = (data) => {
    localStorage.setItem("active_profile", data);
}
export const getUserProfile = () => {
    return localStorage.getItem("active_profile");
}
export const getActiveRole = () => {
    return localStorage.getItem("active_role")
}

export const getActiveTenant = () => {
    return localStorage.getItem("active_tenant")
}

// logout user
export const logout = async token => await axios(token).post(`logout`, token);

export const auth = async token => await axios(token).get(`auth`);

export const checkToken = async (token) => await commonAxios.get(`checkToken/${token}`);

export const ResetPassword = async (data) => await commonAxios.post('resetPassword',data);

export const ForgotPassword = async (data) => await commonAxios.post('forgotPassword',data);

// apis for email button
export const checkSeasonStatus = async (token) => await commonAxios.get(`season-rsvp/${token}`);

export const checkMatchStatus = async (token) => await commonAxios.get(`match-rsvp/${token}`);