<template>
  
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>Log in</title>
</head>
<body class="hold-transition login-page">
<div class="login-box">
  <!-- /.login-logo -->
  <div class="card card-outline card-primary">
    <div class="card-header text-center">
      <img src="../../src/assets/img/HR-Logo-Texture.svg" style="height:100px"/>
    </div>
    <div class="card-body">
      <p class="login-box-msg">Login below with your email address and password</p>

      <form @submit.prevent="onLoginHandler" method="post">
        <div class="input-group mb-3">
          <input type="email" name="email" v-model="form.email" class="form-control" placeholder="Email">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
        </div>
       <small class="text-danger">{{errors?.email?.[0]}}</small>
        <div class="input-group mb-3">
          <input v-if="showPassword" type="text" name="password" v-model="form.password" class="form-control" placeholder="Password">
          <input v-else type="password" name="password" v-model="form.password" class="form-control" placeholder="Password">
          <div class="input-group-append">
            <div class="input-group-text">
              <a @click="toggleShow">
                <span class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></span>
              </a>
            </div>
          </div>
        </div>
         <small class="text-danger">{{errors?.password?.[0]}}</small>
         <router-link to="/forgot-password" class="nav-link login-box-msg p-0 text-right">Forgot Password ?</router-link>
        <!--<small class="text-danger">{{errors?.message}}</small>-->
        <div class="row justify-content-center mt-3">
          <!-- <div class="col-8">
            <div class="icheck-primary">
              <input type="checkbox" id="remember">
              <label for="remember">
                Remember Me
              </label>
            </div>
          </div> -->
          <!-- /.col -->
          
          <div class="col-4">
            <!-- <button type="submit" class="btn btn-primary btn-block" :class="disable? 'disabled' : ''" >Sign In</button> -->
            <button type="submit" class="btn btn-primary btn-block" >Sign In</button>
          </div>
          <!-- /.col -->
        </div>
      </form>
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</div>
<!-- /.login-box -->

</body>
</html>

</template>

<script>
// import {ErrorMessage } from "vee-validate";
import {login, setUserData} from "@/services/userService"
import Swal from "sweetalert2"
import League from '@/models/league/League'
import Season from '@/models/season/Season'

export default {
  name: 'Admin-login',
  data(){
    return {
      form: {
        email: '',
        password: ''
      },
      showPassword: false,
      errors: {}
    }
  },
  mounted() {
     this.makeBodyCollaspable()
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    makeBodyCollaspable() {
        let width = window.screen.width;
        if(width < 991) {
          console.log(width)
          let body =  document.getElementById('app-body')
          console.log(body)
            body.classList.add('sidebar-collapse')
            body.classList.remove('sidebar-open')
            body.classList.add('sidebar-closed')
        }
      },
    async onLoginHandler() {
      await login(this.form)
      .then(async res=> {
        this.errors = res.data.error;
        if(this.errors == undefined || this.errors == null) {
           Swal.fire({
            icon: 'success',
            title: "Welcome to happy roster's admin panel",
            showConfirmButton: false,
            timer: 1500
          });
          League.deleteAll()
          Season.deleteAll()
          setUserData(res.data.data)
           this.$router.push({name: "home"});
        }
      }).catch (err => {
        this.errors = err?.response?.data;
        if(this.errors) {
          let title ='Something went wrong'
          if(this.errors.error != null) {
            title = this.errors.error
          }
        Swal.fire({
            icon: 'error',
            title: title,
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    },

    computed: {
      buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
      }
    },

    disable() {
      if(this.form.email == '' ||
        this.form.password == '') {
          // Object.keys( this.errors ).length != 0
        return true;
      }
    }
  },
}
</script>

