<template>

    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <title>Log in</title>
    </head>
    <body class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <img src="../../src/assets/img/HR-Logo-Texture.svg" style="height:100px"/>
        </div>
        <div class="card-body">
          <p class="login-box-msg">Reset your password</p>
    
          <form @submit.prevent="onSubmitHandler" method="post">
            <div class="input-group mb-3">
              <input v-if="showFirstPassword" type="text" name="password" v-model="form.password" class="form-control" placeholder="Password">
              <input v-else type="password" name="password" v-model="form.password" class="form-control" placeholder="Password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <a @click="toggleFirstShow">
                    <span class="fas" :class="{ 'fa-eye-slash': showFirstPassword, 'fa-eye': !showFirstPassword }"></span>
                  </a>
                </div>
              </div>
            </div>
           <small class="text-danger">{{errors?.email?.[0]}}</small>
            <div class="input-group mb-3">
              <input v-if="showPassword" type="text" name="password" v-model="form.password_confirmation" class="form-control" placeholder="Confirm Password">
              <input v-else type="password" name="password" v-model="form.password_confirmation" class="form-control" placeholder="Confirm Password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <a @click="toggleShow">
                    <span class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></span>
                  </a>
                </div>
              </div>
            </div>
             <small class="text-danger">{{errors?.password?.[0]}}</small>
            <!--<small class="text-danger">{{errors?.message}}</small>-->
            <div class="row justify-content-center mt-3">
              <!-- <div class="col-8">
                <div class="icheck-primary">
                  <input type="checkbox" id="remember">
                  <label for="remember">
                    Remember Me
                  </label>
                </div>
              </div> -->
              <!-- /.col -->
    
              <div class="col-5">
                <!-- <button type="submit" class="btn btn-primary btn-block" :class="disable? 'disabled' : ''" >Sign In</button> -->
                <button type="submit" class="btn btn-primary btn-block" :disabled="isSubmit">{{ isSubmit ? "Submitting..." :
                        "Submit" }}</button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <router-link to="/" class="nav-link login-box-msg mt-4 pb-0">Login ?</router-link>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.login-box -->
    
    </body>
    </html>
    
    </template>
    
    <script>
    // import {ErrorMessage } from "vee-validate";
    import {checkToken, ResetPassword} from "@/services/userService"
    import Swal from "sweetalert2"
    export default {
      name: 'Password-Reset',
      data(){
        return {
          isSubmit:false,
          form: {
            password: '',
            password_confirmation: '',
            token: this.$route.params.token
          },
          showPassword: false,
          showFirstPassword:false,
          errors: {},
        }
      },
      mounted() {
         this.makeBodyCollaspable();
         this.validateToken()
      },
      methods: {
        toggleShow() {
          this.showPassword = !this.showPassword;
        },
        toggleFirstShow() {
          this.showFirstPassword = !this.showFirstPassword;
        },
        async validateToken(){
          await checkToken(this.form.token)
          .then(async res=> {
            let response = res.data;
            if(response.status == false) {
              this.$router.push({name:'home'});
            }
          }).catch (err => {
            this.errors = err?.response?.data;
          })
        },
        makeBodyCollaspable() {
            let width = window.screen.width;
            if(width < 991) {
              console.log(width)
              let body =  document.getElementById('app-body')
              console.log(body)
                body.classList.add('sidebar-collapse')
                body.classList.remove('sidebar-open')
                body.classList.add('sidebar-closed')
            }
          },
        async onSubmitHandler() {
          this.isSubmit = true;
          await ResetPassword(this.form)
          .then(async res=> {
            let response = res.data;
    
            if(response.status == 1) {
                Swal.fire({
                  icon: 'success',
                  title: "Password reset successfully. You can login now with your email and password",
                  showConfirmButton: false,
                  timer: 2500
                })
                this.isSubmit = false;
                this.$router.push({name: "login"});
            } else {
              this.isSubmit = false;
              Swal.fire({
                icon: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 2000
              })
            }
          }).catch (err => {
            this.isSubmit = false;
            this.errors = err?.response?.data;
            if(this.errors) {
              let title ='Opps Something went wrong.'
              if(this.errors.error != null) {
                title = this.errors.error
              }
            Swal.fire({
                icon: 'error',
                title: title,
                showConfirmButton: false,
                timer: 1500
              })
            }
          })
        },
    
        computed: {
          buttonLabel() {
          return (this.showPassword) ? "Hide" : "Show";
          }
        },
    
        disable() {
          if(this.form.email == '' ||
            this.form.password == '') {
              // Object.keys( this.errors ).length != 0
            return true;
          }
        }
      },
    }
    </script>