import { createApp } from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'
import './assets/css/adminlte.min.css';
import './assets/css/custom.css';
import router from './router'
import Store from '@/vuexRegister/league/store'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
  };

const app = createApp(App);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBPPuRTGCytw274gq886eVNILejwyyh5WI',
    libraries: "places" 
  },
});

const settingConfig = {
    methods: {},
    install(app) {
      app.config.globalProperties.$externalMethods = {
        set: (method, func) => this.methods[method] = func,
        call: (method, ...args) => this.methods[method](...args)
      }
    }
};

app.use(Store).use(router, VueSweetalert2, options).use(settingConfig).mount('#app')


