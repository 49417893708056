import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
import {auth, setUserProfile} from "@/services/userService";
import RsvpStatus from "@/components/RsvpStatus.vue";
import MatchStatus from "@/components/MatchStatus.vue";
import Password from "@/components/Password.vue";
import PasswordForgot from "@/components/PasswordForgot.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: "/season-rsvp/:token",
    name: "updateSeasonRsvp",
    component: RsvpStatus,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: "/match-rsvp/:token",
    name: "updateMAtchRsvp",
    component: MatchStatus,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: "/password-reset/:token",
    name: "passwordReset",
    component: Password,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: "/forgot-password",
    name: "passwordForgot",
    component: PasswordForgot,
    meta: {
        requiresAuth: false
    }
  },
  {
    path: "/dashboard",
    name: "home",
    component: () => import('@/components/Home.vue') ,
    meta: {
        requiresAuth: true
    }
  },
  {
    path: "/leagues",
    name: "leagues",
    component: () => import('@/components/leagues/League.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/league/create",
    name: "create_league",
    component: () => import('@/components/leagues/AddLeague.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/league/edit/:id",
    name: "edit_league",
    component: () => import('@/components/leagues/EditLeague.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/seasons",
    name: "seasons",
    component: () => import('@/components/seasons/Season.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/season/create",
    name: "create_season",
    component: () => import('@/components/seasons/AddSeason.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/season/edit/:id",
    name: "edit_season",
    component: () => import('@/components/seasons/EditSeason.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/seasons/invitations",
    name: "invitations",
    component: () => import('@/components/seasons/SeasonInvites.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/seasons/add_player",
    name: "add_players",
    component: () => import('@/components/seasons/AddPlayer.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/seasons/requests",
    name: "season_requests",
    component: () => import('@/components/seasons/SeasonRequest.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/seasons/manage_players_role",
    name: "manage_players_role",
    component: () => import('@/components/seasons/PlayerRole.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/games",
    name: "games",
    component: () => import('@/components/games/Index.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/games/create",
    name: "create_games",
    component: () => import('@/components/games/Add.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/games/edit/:id",
    name: "edit_games",
    component: () => import('@/components/games/Edit.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/games/view-players/:id",
    name: "view_game_players",
    component: () => import('@/components/games/ViewPlayers.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/games/results",
    name: "game_results",
    component: () => import('@/components/games/GameResults.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/games/logs/:id",
    name: "game_logs",
    component: () => import('@/components/games/Logs.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/payments/create",
    name: "create_payment",
    component: () => import('@/components/payments/Add.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/teams/assign",
    name: "assign_teams",
    component: () => import('@/components/teams/AssignTeam.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/teams/members",
    name: "team_members",
    component: () => import('@/components/teams/TeamMembers.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/teams/stats",
    name: "stats",
    component: () => import('@/components/teams/TeamStats.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/tenants",
    name: "tenants",
    component: () => import('@/components/admin/Tenants.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/tenants/add",
    name: "tenant_add",
    component: () => import('@/components/admin/TenantAdd.vue') ,
     meta: {
        requiresAuth: true
    }
  },
  {
    path: "/tenants/edit/:id",
    name: "edit_tenants",
    component: () => import('@/components/admin/TenantEdit.vue') ,
     meta: {
        requiresAuth: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  
    const token = localStorage.getItem('api_token');
    const active_tenant = localStorage.getItem('active_tenant');

   // let user = "";
    let isAuthenticated = false;
    
    let isTenantRedirect = false;

    if(token != null || token != undefined){

        await auth(token).then((res) => {

         //  user = res.data;
          let user = res.data;
          setUserProfile(user);
          isAuthenticated = true;
          if(user.role_id == 1 && null == active_tenant && !to.path.includes('tenant')) {
           isAuthenticated = false;
           isTenantRedirect = true;
          }

        }).catch(() => {

            isAuthenticated = false;
        })  
    }

    if(to.name == "updateMAtchRsvp" || to.name == "updateSeasonRsvp") {
      isAuthenticated = false;
    }
    
    if (to.matched.some(record => record.meta.requiresAuth)) {
        
        if(isTenantRedirect) {
          next({ name: 'tenants' })
        } else if (isAuthenticated == false) {
          next({ name: 'login' })
        }else {
          
          next()
        }
    } else {

        if (isAuthenticated == true) {
          next({ name: 'home' })
        }else {
          
          next()
        }
        
    }
})



export default router;