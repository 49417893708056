<template>
  
  <router-view></router-view>
</template>

<script>

import {getUserToken} from "@/services/userService"
export default {
  name: 'App',

   computed:{
      token(){
        return getUserToken();
      }
    },

     mounted() {
          console.log(this.token,'mounted')
     }
}
</script>

