<template>
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>RSVP</title>
    </head>

    <body class="hold-transition login-page">
        <div class="login-box">
            <!-- /.login-logo -->
            <div class="card card-outline card-primary">
                <div class="card-header text-center">
                    <img src="../../src/assets/img/HR-Logo-Texture.svg" style="height:100px" />
                </div>
                <div class="card-body">
                    <p class="login-box-msg" v-if="loading">Please wait...</p>

                    <form method="post">

                        <!--<small class="text-danger">{{errors?.message}}</small>-->
                        <div class="row justify-content-center mt-3">
                            <h5 v-if="status == true">{{message}}</h5>
                            <h5 v-else-if="status == 3">Link has been expired</h5>
                            <h5 v-if="error">Link has been expired</h5>
                        </div>
                    </form>
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </div>
        <!-- /.login-box -->

    </body>

    </html>
</template>
    
<script>
// import {ErrorMessage } from "vee-validate";
import { checkMatchStatus } from "@/services/userService"

export default {
    name: 'RSVP-Status',
    data() {
        return {
            token: this.$route.params.token,
            error:false,
            success:false,
            loading:true,
            status:false,
            message: ''
        }
    },
    mounted() {
        this.checkStatus();
    },
    methods: {
        async checkStatus() {
            await checkMatchStatus(this.token)
                .then(async res => {
                    this.errors = res.data.error;
                    this.status = res.data.status;
                    this.loading = false;

                    if(res.data.status == true){
                        this.message = res.data.msg;
                    }

                    if (this.errors == undefined || this.errors == null) {
                        this.success = true;                        
                    }
                    if(this.status == false) {
                        this.error = true;
                    }
                }).catch(err => {
                    this.loading = false;
                    this.errors = err?.response?.data;
                    if (this.errors) {
                        this.error = true;
                    }
                })
        },

    },
}
</script>