import { Model } from '@vuex-orm/core';

export default class League extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'leagues'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      organiser_id: this.attr(),
      league_image: this.attr(''),
      colour_id: this.attr(),
      isSelected: this.attr(false)
    }
  }
}